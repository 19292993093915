export const useStoryblokApiOptions = () => {
  const storyblokStore = useStoryblokStore();
  const config = storyblokStore.runtimeConfig;
  const { $i18n } = useNuxtApp();

  return {
    ...config.public.storyblok.apiOptions,
    cv: Date.now(),
    language: $i18n.locale.value,
  };
};
