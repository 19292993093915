import {
  indexVuexStore,
  shopVuexStore 
} from "#imports"
const VuexStore = {
  ...indexVuexStore,
  modules: {
      shop: {
        ...shopVuexStore,
        namespaced: true,
        modules: {},
      }
    }, 
  }
export default VuexStore