export default defineNuxtPlugin((nuxtApp) => {
  // setTimeout(() => {
  //   if (process.client) {
  //     let script = document.createElement("script");
  //     script.src =
  //       "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=667e63d40e1f4b734e2e12d7";
  //     script.defer = true;
  //     document.body.appendChild(script);
  //     script = document.createElement("script");
  //     script.src = "/assets/js/webflow.js";
  //     script.defer = true;
  //     document.body.appendChild(script);
  //   }
  //   console.log("onMounted");
  // }, 1000);
});
