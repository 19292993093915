export const useUserPageInteraction = (callback: () => {}) => {
  // Parameters for showing the popup
  const scrollPercentageTrigger = 50; // Show popup when scrolled 50%
  const timeSpentTrigger = 20000; // Show popup after 20 seconds (in milliseconds)

  let isScrollTriggered = false;
  let isTimeTriggered = false;

  // Track time spent on the page
  const timer = setTimeout(() => {
    isTimeTriggered = true;
    checkShowPopup();
  }, timeSpentTrigger);

  // Function to calculate scroll depth
  const handleScroll = () => {
    return;
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;
    const bodyHeight = document.body.scrollHeight - windowHeight;
    const scrollPercentage = (scrollPosition / bodyHeight) * 100;

    if (scrollPercentage >= scrollPercentageTrigger) {
      isScrollTriggered = true;
      checkShowPopup();
    }
  };

  // Function to determine whether to show the popup
  const checkShowPopup = () => {
    if (isTimeTriggered) {
      callback();
      cleanup(); // Cleanup event listeners and timers
    }
  };

  // Attach event listeners
  onMounted(() => {
    // window.addEventListener("scroll", handleScroll);
  });

  // Cleanup event listeners and timers
  const cleanup = () => {
    clearTimeout(timer);
    // window.removeEventListener("scroll", handleScroll);
  };

  // Detach event listeners on component unmount
  onUnmounted(() => {
    cleanup();
  });
};
