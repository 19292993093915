// import { md3 } from 'vuetify/blueprints'
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
// Data tables
import * as labsComponents from "vuetify/labs/components";

// import PerfectScrollbar from "vue3-perfect-scrollbar";
// import VueApexCharts from "vue3-apexcharts";
// import VueTablerIcons from "vue-tabler-icons";

// Mock Api data
// import "./_mockApis";

// import VCalendar from "v-calendar";

// import Maska from "maska";
import "vue3-carousel/dist/carousel.css";
import "./assets/scss/style.scss";

// DragScroll
// import { VueDraggableNext } from "vue-draggable-next";

// Table
// import Vue3EasyDataTable from "vue3-easy-data-table";
// import "vue3-easy-data-table/dist/style.css";

// i18
// import { createI18n } from "vue-i18n";
// importiere die Lokalisierungsdaten für jede Sprache
import de from "vuetify/lib/locale/de";
import en from "vuetify/lib/locale/en";

import { ZWEIKERN_THEME } from "./theme/LightTheme";
import { DARK_ZWEIKERN_THEME } from "./theme/DarkTheme";

export default {
  ssr: false,
  // blueprint: md3,
  components: {
    // draggable: VueDraggableNext,
    labsComponents,
    ...components,
  },
  directives,
  locale: {
    locale: "de",
    fallback: "en",
    messages: { de, en },
  },
  theme: {
    defaultTheme: "DARK_ZWEIKERN_THEME",
    themes: {
      ZWEIKERN_THEME,
      DARK_ZWEIKERN_THEME,
    },
  },
  defaults: {
    VExpansionPanel: {},
    VCard: {
      rounded: "md",
      elevation: 10,
    },

    VTextField: {
      variant: "outlined",
      density: "comfortable",
      color: "primary",
      hideDetails: "auto",
    },
    VTextarea: {
      variant: "outlined",
      density: "comfortable",
      color: "primary",
      hideDetails: "auto",
    },
    VSelect: {
      variant: "outlined",
      density: "comfortable",
      color: "primary",
      hideDetails: "auto",
    },
    VListItem: {
      minHeight: "45px",
    },
    VTooltip: {
      location: "top",
    },
    VBtn: {
      style: "text-transform: capitalize; letter-spacing:0",
      rounded: "md",
      minWidth: "24px",
      variant: "flat",
    },
    VAutocomplete: {
      variant: "outlined",
      dense: "compact",
      hideDetails: "auto",
    },
    VSwitch: {
      hideDetails: "auto",
      color: "secondary",
    },
    VSlider: {
      hideDetails: "auto",
    },
    VRadioGroup: {
      hideDetails: "auto",
    },
  },
};
