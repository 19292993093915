export const state = () => ({
  category: null,
});

export const mutations = {
  SET_CATEGORY(state, category) {
    state.category = category;
  },
};

export const getters = {
  getCategory(state) {
    return state.story;
  },
};

export const actions = {
  setCategory({ commit }, category) {
    commit("SET_CATEGORY", category);
  },
};

export default { state, actions, mutations, getters };
