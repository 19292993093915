import type { ThemeTypes } from "@/types/themeTypes/ThemeType";

const ZWEIKERN_THEME: ThemeTypes = {
  name: "ZWEIKERN_THEME",
  dark: false,
  variables: {
    "border-color": "#e5eaef",
  },
  colors: {
    primary: "#A2027B",
    secondary: "#EB6E08",
    info: "#539BFF",
    success: "#13DEB9",
    accent: "#FFAB91",
    warning: "#FFAE1F",
    error: "#FA896B",
    lightprimary: "#f3e0ef",
    lightsecondary: "#E8F7FF",
    lightsuccess: "#E6FFFA",
    lighterror: "#FDEDE8",
    lightinfo: "#EBF3FE",
    lightwarning: "#FEF5E5",
    textPrimary: "#2A3547",
    textSecondary: "#2A3547",
    borderColor: "#e5eaef",
    inputBorder: "#DFE5EF",
    containerBg: "#ffffff",
    background: "#ffffff",
    hoverColor: "#f6f9fc",
    surface: "#ffffff",
    "on-surface-variant": "#ffffff",
    grey100: "#F2F6FA",
    grey200: "#EAEFF4",
  },
};

export { ZWEIKERN_THEME };
