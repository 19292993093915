export const state = () => ({
  isNavWhite: false,
  appConfig: null,
  config: null,
  story: null,
  links: [],
  blogLinks: [],

  category: null,
});

export const mutations = {
  SET_APP_CONFIG(state, config) {
    state.appConfig = config;
    console.log("SET_APP_CONFIG !!");
  },
  SET_CONFIG(state, config) {
    state.config = config;
    console.log("SET_CONFIG !!");
  },
  SET_STORY(state, story) {
    state.story = story;
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_BLOG_LINKS(state, links) {
    state.blogLinks = links;
  },
  SET_NAV_WHITE(state, isNavWhite) {
    state.isNavWhite = isNavWhite;
  },

  SET_CATEGORY(state, category) {
    state.category = category;
  },
};

export const getters = {
  getAppConfig(state) {
    return state.appConfig;
  },
  getConfig(state) {
    return state.config;
  },
  getStory(state) {
    return state.story;
  },
  getLinks(state) {
    return state.links;
  },
  getBlogLinks(state) {
    return state.blogLinks;
  },
  navWhite(state) {
    return state.isNavWhite;
  },

  getCategory(state) {
    return state.story;
  },
};

export const actions = {
  setAppConfig({ commit }, config) {
    commit("SET_APP_CONFIG", config);
  },
  setConfig({ commit }, config) {
    commit("SET_CONFIG", config);
  },
  setStory({ commit }, story) {
    commit("SET_STORY", story);
  },
  setLinks({ commit }, links) {
    commit("SET_LINKS", links);
  },
  setBlogLinks({ commit }, links) {
    commit("SET_BLOG_LINKS", links);
  },
  navWhite({ commit }, isNavWhite) {
    commit("SET_NAV_WHITE", isNavWhite);
  },

  setCategory({ commit }, category) {
    commit("SET_CATEGORY", category);
  },
};

export default { state, actions, mutations, getters };
