export default defineNuxtPlugin(() => {
  if (typeof window !== "undefined") {
    function reveal(querySelector, elementVisible = 150, delay = 0) {
      // console.log("reveal animation");
      setTimeout(() => {
        const reveals = document.querySelectorAll(querySelector);

        for (let i = 0; i < reveals.length; i++) {
          const windowHeight = window.innerHeight;
          const elementTop = reveals[i].getBoundingClientRect().top;

          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            // reveals[i].classList.remove("active");
          }
        }
      }, delay);
    }

    window.addEventListener("scroll", () => reveal(".reveal-text", 50));
    window.addEventListener("scroll", () => reveal(".reveal-image", 0, 600));

    reveal(".reveal-text", -100);
    reveal(".reveal-image", 0, 600);
  }
});
