import { createVuetify } from "vuetify";

import vuetifyConfig from "@/vuetify.config";
import * as themeColors from "@/theme/LightTheme";
import * as DarkThemeColors from "@/theme/DarkTheme";

export default defineNuxtPlugin((nuxtApp) => {
  const config = reactive(vuetifyConfig);
  const vuetify = createVuetify(config);
  nuxtApp.vueApp.use(vuetify);
});
