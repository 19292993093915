import type { ThemeTypes } from "@/types/themeTypes/ThemeType";

const DARK_ZWEIKERN_THEME: ThemeTypes = {
  name: "DARK_ZWEIKERN_THEME",
  dark: true,
  variables: {
    "border-color": "#333F55",
  },
  colors: {
    primary: "#A2027B",
    secondary: "#EB6E08",
    info: "#539BFF",
    success: "#13DEB9",
    accent: "#FA896B",
    warning: "#FFAE1F",
    error: "#FA896B",
    lightprimary: "#26153C",
    lightsecondary: "#09454B",
    lightsuccess: "#1B3C48",
    lighterror: "#4B313D",
    lightinfo: "#223662",
    lightwarning: "#4D3A2A",
    textPrimary: "#EAEFF4",
    textSecondary: "#7C8FAC",
    borderColor: "#333F55",
    inputBorder: "#465670",
    containerBg: "#171c23",
    background: "#171c23",
    hoverColor: "#333f55",
    surface: "#000000",
    "on-surface-variant": "#171c23",
    grey100: "#333F55",
    grey200: "#465670",
  },
};

export { DARK_ZWEIKERN_THEME };
