import validate from "/Users/dennisdarko/Sites/zweikern-website_v2/node_modules/nuxt/dist/pages/runtime/validate.js";
import i18n_45global from "/Users/dennisdarko/Sites/zweikern-website_v2/middleware/i18n.global.js";
import token_45global from "/Users/dennisdarko/Sites/zweikern-website_v2/middleware/token.global.js";
import manifest_45route_45rule from "/Users/dennisdarko/Sites/zweikern-website_v2/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  i18n_45global,
  token_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}