export default defineNuxtRouteMiddleware(async (to, from) => {
  const token = to?.query?.token;
  const redirectCookie = useCookie("zweikern_token_redirect");
  const { cookie } = useMyPage();

  if (token && redirectCookie?.value && to.path === from.path) {
    const { data } = await useMailchimp().verifySubscription(token);
    if (data?.status === "subscribed") {
      cookie.set("subscribed", { expires: 365 });
      return decodeURIComponent(`/${redirectCookie?.value}`);
    }
  }
});
