import { usePageLeave } from "@vueuse/core";

export const useMyPage = ({ display } = {}) => {
  const cookie = useCookie("zweikern_page_exit", {
    expires: new Date(new Date().setDate(new Date().getDate() + 7)),
  });

  const isLeft = usePageLeave();

  const dialog = ref({
    show: false,
    bind: {
      maxWidth: 450,
      persistent: false,
      scrollable: true,
    },
    events: {
      "click:outside": () => {
        dialog.value.show = false;
      },
    },
  });

  if (display?.mdAndDown?.value) {
    useUserPageInteraction(() => {
      dialog.value.show = true;
    });
  }

  function handleBeforeUnload() {
    if (!cookie.value) {
      dialog.value.show = true;
      cookie.value = true;
    }
  }

  function watchPageLeave(callback: () => void) {
    watchEffect(() => {
      if (isLeft.value) {
        callback();
        handleBeforeUnload();
      }
    });
  }

  function changeExpires(days: number) {
    // Neues Ablaufdatum berechnen
    const newExpiryDate = new Date();
    newExpiryDate.setDate(newExpiryDate.getDate() + days);

    document.cookie = `zweikern_page_exit=${cookie.value}; expires=${newExpiryDate.toUTCString()}; path=/`;
  }

  return {
    cookie: {
      get: () => cookie.value,
      set: (value: any, options: object = {}) => {
        cookie.value = value;

        if (options?.expires) {
          changeExpires(options?.expires);
        }
      },
    },
    watchPageLeave,
    dialog,
  };
};
